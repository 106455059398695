/* VARIABLES */

@body: black;
@variables: blue;
@inputs: darkred;

.cursive {
  font-family: 'Grand Hotel', cursive;
  letter-spacing: 2px;
	font-size: 20px;
  text-align: center;  // Firefox
  text-align-last: center; // Chrome
}

.form-input {
  vertical-align: bottom;
  width: 40%;
  border: 0px;
  border-bottom: 1px black solid;
  border-radius:0px;
}